
//公共库
import { Tree } from "element-ui";
import { cloneDeep } from "lodash";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import { UserModule } from "@/store/modules/user";
import { setPermissionRole, delPermissionRole, getPermissionRole } from "@/api/request/admin";

//组件
@Component({
  name: "Permission",
})

//界面函数
export default class extends Vue {
  //定义变量
  private rolesList: any[] = [];
  private serviceRoutes: any[] = [];
  private reshapedRoutes: any[] = [];
  private listLoading: boolean = false;

  //创建
  created() {
    //获取角色列表
    this.getRoleMenu();

    //获取数据
    this.getRoles();
  }

  //获取角色权限数据
  private async getRoles() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getPermissionRole({});

    //数据赋值
    this.rolesList = data.result;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取角色列表
  private getRoleMenu(): void {
    //数据赋值
    this.serviceRoutes = cloneDeep(UserModule.routeCfg);
    this.reshapedRoutes = cloneDeep(UserModule.routeCfg);
  }

  //新增角色权限
  private handleCreateRole(): void {
    //数据赋值
    this.role = {
      id: 0, //角色ID
      role_name: "", //角色名称
      perm_config: '""', //使用json格式存储配置信息
    };
    this.dialogType = "create";

    //显示对话框
    this.dialogVisible = true;

    //初始化选中权限
    if (this.$refs.tree) {
      (this.$refs.tree as Tree).setCheckedKeys([]);
    }
  }

  //编辑角色权限
  private handleEditRole(row: any): void {
    //数据赋值
    this.dialogType = "edit";
    this.checkStrictly = true;
    this.role = cloneDeep(row.row);

    //显示对话框
    this.dialogVisible = true;

    //延迟显示
    this.$nextTick(() => {
      //设置选中权限
      let list = this.routesKeys;
      const permConfig = GFunc.checkstring(this.role.perm_config);
      if (permConfig !== "*") {
        let listCheckedKeys: any = [];
        if (permConfig) {
          listCheckedKeys = JSON.parse(permConfig);
        }
        list = this.routesKeys.filter((item) => {
          if (listCheckedKeys.includes(item)) {
            return item;
          }
        });
      }
      (this.$refs.tree as Tree).setCheckedKeys(list);
      this.checkStrictly = false;
    });
  }

  //删除角色权限
  private handleDeleteRole(scope: any): void {
    //数据赋值
    const { $index, row } = scope;

    //提示
    this.$confirm(`您确定删除 ${row.role_name} 角色权限？`, "警告", {
      type: "warning",
      cancelButtonText: this.$t("permission.cancel") as string,
      confirmButtonText: this.$t("permission.confirm") as string,
    })
      .then(async () => {
        //删除权限
        await delPermissionRole({ id: row.id });

        //数据赋值
        this.rolesList.splice($index, 1);

        //显示提示
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //--------------------------------- 添加/编辑角色 ---------------------------------
  //角色数据
  private role: any = {
    id: 0, //角色ID
    role_name: "", //角色名称
    perm_config: '""', //使用json格式存储配置信息
  };

  //定义变量
  private routesKeys: Number[] = [];
  private dialogType: string = "create";
  private checkStrictly: boolean = false;
  private dialogVisible: boolean = false;
  private defaultProps: any = { children: "children", label: "title" };
  private titleMap: any = { edit: "编辑角色权限", create: "添加角色权限" };

  //当前路由数据
  get routesTreeData() {
    //返回数据
    return this.generateTreeData(this.reshapedRoutes);
  }

  //创建/修改角色
  private async confirmRole() {
    //数据赋值
    const checkedKeys = (this.$refs.tree as Tree).getCheckedKeys();
    this.role.perm_config = JSON.stringify(checkedKeys);

    //设置角色
    await setPermissionRole(this.role);

    //隐藏对话框
    this.dialogVisible = false;

    //获取数据
    this.getRoles();

    //显示提示
    this.$notify({
      title: "成功",
      duration: 2000,
      type: "success",
      message: "操作成功",
    });
  }

  //全选
  private onChooseAll(): void {
    //数据赋值
    (this.$refs.tree as Tree).setCheckedKeys(this.routesKeys);
  }

  //反选
  private onChooseReverse(): void {
    const listCheckedKeys = (this.$refs.tree as Tree).getCheckedKeys();
    const list = this.routesKeys.filter((item) => {
      if (!listCheckedKeys.includes(item)) {
        return item;
      }
    });
    (this.$refs.tree as Tree).setCheckedKeys(list);
  }

  //获取数据
  private generateTreeData(routes: any): any[] {
    //定义变量
    const data: any[] = [];

    //数据赋值
    this.routesKeys = [];
    for (const route of routes) {
      //超级管理员
      if (route === "*") continue;

      //数据赋值
      const tmp: any = { id: route.m_id, title: route.m_name };

      //列表赋值
      data.push(tmp);
      this.routesKeys.push(route.m_id);
    }

    //返回数据
    return data;
  }
}
